import ApiConstants from '../Constants/ApiConstants';
import { AxiosRequestConfig } from 'axios';
import PageContextManager from 'page-context-manager';

/**
 * Get current axios configuration for axios api requests
 * It automatically chooses between default or service mode one
 */
const getDefaultConfiguration = (): AxiosRequestConfig => {
    const { serviceUrl } = PageContextManager.getSiteData();

    const configuration: AxiosRequestConfig = getServiceConfiguration(serviceUrl);

    return configuration;
};

/**
 * Get default axios configuration for axios api requests
 */
const getNonServiceConfiguration = (): AxiosRequestConfig => {
    const returnedConfig: AxiosRequestConfig = {
        timeout: ApiConstants.DefaultTimeout,
    };

    return returnedConfig;
};

/**
 * Get service mode axios configuration for axios api requests
 */
const getServiceConfiguration = (serviceUrl: string): AxiosRequestConfig => {
    let returnedConfig: AxiosRequestConfig = {
        timeout: ApiConstants.DefaultTimeout,
    };

    returnedConfig.baseURL = new URL(serviceUrl || window.location.origin).origin;

    return returnedConfig;
};

export default {
    getDefaultConfiguration,
    getNonServiceConfiguration,
    getServiceConfiguration,
};
